import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import review from "../images/reviweer.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 1200,
    speed: 1500,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "We use Acton Airport Taxi regularly because they are always on time, courteous and professional. ",
    },
    {
      icon: review,
      title: "Heidi Schumacher",
      date: "May 8 2022",
      description:
        "I have used this car service 3 times and they have been very reliable and well priced. Cars are clean and drivers are friendly, on time all three times. Great alternative to the yellow cab. I definitely recommend them. ",
    },
    {
      icon: review,
      title: "Irene E. Snyder",
      date: "July 8 2022",
      description:
        "Great ride and same price as a regular taxi. I used to pay same money by Acton taxi but this new company has the best clean car service with same price of taxi, nice service, and good driver. Don’t have to smell cigarette in taxi anymore. I just did booked return trip too.",
    },
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap homepage-review  pb-5">
        <Container fluid>
          <Row>
            <Col  lg="6">
              <h2 className="section__title text-left">
                What Customer Say <br />
                About <span> Us </span>
              </h2>
              <p className="text-left">
                What do our customers feel when they are with Carrent, check
                this next, guys
              </p>
            </Col>
            <Col  lg="6">
              <Slider {...settings}>
                {Info.map((info, i) => (
                  <div className="reviewitem" key={i}>
                    <div className="reviewer-info">
                      <img src=/img/1.png alt="" />
                      <h5>
                        {info.title}
                        <small>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </small>
                      </h5>
                    </div>
                    <p className="text-left">{info.description}</p>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
